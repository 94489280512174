<template>
  <top></top>
  <div class="container">
    <div class="row contentTop">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3 leftBd">
        <ul>
          <dt @click="evaluations">战略合作</dt>
          <dt @click="newsLst">新闻动态</dt>
          <dt @click="noticecLst">公示公告</dt>
          <dt @click="forumLst">教育论坛</dt>
          <dd></dd>
          <li><img :src="erwei" class="img-responsive center-block" /></li>
          <li>电话:010-57108081</li>
        </ul>
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-9 minTop">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 newTop">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>战略合作</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 newLst" v-for="item in newestList" :key="item.notice_id">
            <li  @click="newsInfo(item.news_id)" v-if="screenWidth <= 768">{{ item.news_title.substring(0, 15) + "..." }}<i>{{ item.update_time }}</i>  </li>
            <li  @click="newsInfo(item.news_id)" v-else >{{ item.news_title }}<i>{{ item.update_time }}</i></li>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fenye">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <bottom></bottom>
</template>

<script>
import { post } from "@/http/api";
import Top from "./top.vue";
import bottom from "./bottom.vue";
import erwei from "@/assets/erwei.png";

export default {
  name: "evaluation",
  components: {
    Top,
    bottom,
    erwei,
  },
  data() {
    return {
      erwei,
      newestList: [],
      total: "",
      pageSize: "",
      currentPage: "1",
      screenWidth: document.body.clientWidth,
    };
  },

  watch: {},

  created() {
    this.newestLists();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },

  methods: {
    newestLists() {
      const qs = require("qs");
      let page = this.currentPage;
      post(
        "Index/evaluationLst",
        qs.stringify({ cate_id: this.$route.params.id, page: page })
      ).then((res) => {
        this.newestList = res.data.evaluationLst;
        this.total = res.data.evaluationNumber;
      });
    },

     newsInfo(news_id){
      console.log(news_id);
      this.$router.push({ path: `/newsInfo/${news_id}` });
      this.$forceUpdate();
    },

    evaluations(){
      this.$router.push({ path: `/evaluation` });
      this.$forceUpdate();
    },

     //跳转新闻列表
    newsLst(){
      this.$router.push({ path: `/newsLst` });
      this.$forceUpdate();
    },

    //跳转公告通知
    noticecLst(){
      this.$router.push({ path: `/notice` });
      this.$forceUpdate();
    },

    //跳转教育论坛
    forumLst(){
      this.$router.push({ path: `/forum` });
      this.$forceUpdate();
    },

    handleCurrentChange(current) {
      this.currentPage = current;
      this.newestLists();
    },
  },
};
</script> 
<style scoped>
@media (max-width: 768px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 3px;
    font-size: 1rem;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-weight: normal;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 0px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 0px;
    text-align: center;
    font-size: 1rem;
  }
  .leftBd ul li img {
    width: 80%;
  }
  :deep .el-breadcrumb {
    font-size: 1rem;
    line-height: 1;
  }
  /* 右侧 */
  .minTop {
    min-height: 365px;
  }
  .newTop {
    margin-bottom: 2%;
  }
  .newLst li {
    padding: 10px 0px;
    color: #333333;
    font-size: 1rem;
    border-bottom: 1px dashed #66666657;
    cursor: pointer;
  }
  .newLst li::marker {
    color: #c99538;
  }
  .newLst li:hover {
    color: #296abc;
  }
  .newLst li:hover i {
    color: #296abc;
  }
  .newLst li i {
    float: right;
    padding-right: 5px;
    font-style: normal;
    font-size: 0.9rem;
    color: #666666;
  }
  .fenye {
    margin-top: 2%;
  }
  .xsBd {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 768px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }
  .newLst li {
    padding: 15px 0px;
    color: #333333;
    font-size: 14px;
    border-bottom: 1px dashed #66666657;
    cursor: pointer;
  }
  .newLst li::marker {
    color: #c99538;
  }
  .newLst li:hover {
    color: #296abc;
  }
  .newLst li:hover i {
    color: #296abc;
  }
  .newLst li i {
    float: right;
    padding-right: 5px;
    font-style: normal;
    font-size: 12px;
    color: #666666;
  }
  .fenye {
    margin-top: 2%;
  }
}

@media (min-width: 992px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }
  .newLst li {
    padding: 15px 0px;
    color: #333333;
    font-size: 14px;
    border-bottom: 1px dashed #66666657;
    cursor: pointer;
  }
  .newLst li::marker {
    color: #c99538;
  }
  .newLst li:hover {
    color: #296abc;
  }
  .newLst li:hover i {
    color: #296abc;
  }
  .newLst li i {
    float: right;
    padding-right: 5px;
    font-style: normal;
    font-size: 12px;
    color: #666666;
  }
  .fenye {
    margin-top: 2%;
  }
}

@media (min-width: 1200px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }
  .newLst li {
    padding: 15px 0px;
    color: #333333;
    font-size: 14px;
    border-bottom: 1px dashed #66666657;
    cursor: pointer;
  }
  .newLst li::marker {
    color: #c99538;
  }
  .newLst li:hover {
    color: #296abc;
  }
  .newLst li:hover i {
    color: #296abc;
  }
  .newLst li i {
    float: right;
    padding-right: 5px;
    font-style: normal;
    font-size: 12px;
    color: #666666;
  }
  .fenye {
    margin-top: 2%;
  }
}
</style>